(function() {
    "use strict";

    const CONSTANTS = {
        maxBookableDaysHence: "30"
    };

    const momentExtensions = {
        isToday: function(momentDate) {
            return momentDate.isSame(moment().startOf("day"), "d");
        }
    };

    const periods = [
        { name: "morning", cutoff: "11:59:59" },
        { name: "afternoon", cutoff: "17:59:59" },
        { name: "evening", cutoff: "23:59:59" }
    ];

    Handlebars.registerHelper("hours",
        function(dateTimeString) {
            const dateTime = moment(dateTimeString);
            return dateTime.format("HH.mm"); //https://momentjs.com/docs/#/displaying/
        });
    Handlebars.registerHelper("openingHours",
        function(timeSpanString) { //eg 08:00:00
            const m = moment(`1970-01-01 ${timeSpanString}`);
            return m.format("HH:mm"); //https://momentjs.com/docs/#/displaying/
        });
    Handlebars.registerHelper("longDate",
        function(dateTimeString) {
            const dateTime = moment(dateTimeString);
            return dateTime.format("dddd Do MMMM"); //https://momentjs.com/docs/#/displaying/
        });
    Handlebars.registerHelper("disabledIfInPast",
        function(dateTimeString) {
            const date = moment(dateTimeString);
            const today = moment();
            return date.isBefore(today, "d") ? "disabled" : "";
        });
    Handlebars.registerHelper("disabledIfTooFarAhead",
        function(dateTimeString) {
            const date = moment(dateTimeString);
            const xDaysHenceDate = moment().add(CONSTANTS.maxBookableDaysHence, "days").startOf("day");
            return date.isSame(xDaysHenceDate, "d") ? "disabled" : "";
        });
    Handlebars.registerHelper("ifPeriodNotInPast",
        function(dateTimeString, periodName, block) { //morning/afternoon/evening
            const dateTime = moment(dateTimeString);
            const isToday = momentExtensions.isToday(dateTime);
            const now = moment();
            const cutoff = periods.filter(function(p) { return p.name === periodName; })[0].cutoff;
            const cutoffTime = moment(cutoff, "HH:mm:ss");

            if (isToday) {
                if (!now.isAfter(cutoffTime)) {
                    return block.fn(this);
                }
                return null;
            }

            return block.fn(this);
        });
    Handlebars.registerHelper("arenaTimeForPrice",
        function(price) {
            const number = parseInt(price);
            return number * 100;
        });
})();